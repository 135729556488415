@import '~antd/dist/antd.css';

html,
body,
#root,
.ll-app {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
}

.ll-app-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

#root > .ll-app > section.ant-layout > main.ant-layout-content {
  overflow: auto;
  display: flex;
  justify-content: center;
  padding: 3rem;
  scroll-behavior: smooth;
}

#root > .ll-app > section.ant-layout > main.ant-layout-content .page-container {
  max-width: 880px;
  align-self: center;
  height: 100%;
}

.ant-btn.ant-btn-primary {
  background: #5693b6;
  border-color: #5693b6;
}
