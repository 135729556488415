.pageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageContainer :global .ant-steps {
  max-width: 400px;
}

.pageContainer
  :global
  .ant-steps
  .ant-steps-item:not(.ant-steps-item-finish)
  .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #ddd;
}

.stepContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
