.ll-invite-user-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.ll-invite-user-container__send-invitation-button {
  align-self: flex-end;
  float: right;
}

.ll-invite-user-emails {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  overflow: auto;
  scroll-behavior: smooth;
  gap: 0.5rem;
  flex: 1;
}

.ll-invite-user-emails .ll-invite-user-email-container {
  display: flex;
  align-items: center;
}
