.login-body {
  display: flex;
  margin: 4rem auto;
  justify-content: center;
  width: 100%;
  max-width: 300px;
  height: 100%;
}

.login-form {
  justify-content: center;
  text-align: center;
  width: 100%;
}

.login-form__app-logo {
  width: 60%;
  margin-bottom: 2rem;
}
