.ll-app-header.ant-layout-header {
  display: flex;
  background: #32557d;
}

.ll-app-header .ant-menu {
  flex: 1;
}

.ll-app-header .ant-menu.ant-menu-dark {
  background: #32557d;
}

.ll-app-header
  .ant-menu.ant-menu-dark
  .ant-menu-submenu.ant-menu-submenu-horizontal {
  border-color: #5693b6;
}

.ll-app-header .ant-menu.ant-menu-dark .ant-menu-item:hover,
.ll-app-header .ant-menu.ant-menu-dark .ant-menu-item.ant-menu-item-selected {
  background-color: #5693b6;
}

.ant-layout-header {
  display: flex;
  align-items: center;
}

.ant-layout-header .ll-app-header__title {
  color: white;
  padding-right: 1rem;
  cursor: pointer;
}

.ant-layout-header .ant-btn:not(:last-child) {
  margin-right: 1rem;
}

.ant-layout-header .ll-app-header__title__icon {
  width: 30px;
  height: 30px;
  margin-right: 1rem;
}
